import { Link as TLink } from 'theme-ui';
import { Link } from "gatsby";
import { ImInstagram } from 'react-icons/im';
import { ImFacebook } from 'react-icons/im';
import * as React from 'react';
export default {
  TLink,
  Link,
  ImInstagram,
  ImFacebook,
  React
};